.App {
  text-align: center;
  background-color: #14173a;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  color: white;
}
.Partners {
  text-align: center;
  background-color: #14173a;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  color: white;
}
.Section2 {
  text-align: center;
  background-color: #fff;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  color: #14173a;
}
.Footer {
  text-align: center;
  background-color: #14173a;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-weight: bold;
  color: white;
}
.Button {
  min-height: 10vh;
  padding: 32px 64px 32px 32px;
  background-color: #14173a;
  text-align: right;
}

.Logo {
  min-height: 10vh;
  padding: 32px 32px 32px 64px;
  text-align: left;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.arrow {
  text-align: center;
  margin: 8% 0;
  color: white;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
